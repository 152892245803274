<template>
    <div class="min-h-screen flex flex-col bg-white dark:bg-gray-900 dark:text-white">
        <Header />
        
        <main class="grow shrink-0 pt-24 sm:pt-20 pb-16 overflow-hidden bg-lines bgx-center">
             <slot />
        </main>

        <Footer />
        <DarkSwitch />
    </div>
</template>

<script setup lang="ts">
useHead({
    htmlAttrs: {class: 'front'},
})
</script>