<template>
    <header class="absolute inset-x-0 top-0 z-50">
        <nav class="flex items-center justify-between max-w-screen-2xl mx-auto p-6 lg:px-8" aria-label="Global">
            <div class="flex lg:flex-1">
                <NuxtLink to="/" class="-m-1.5 p-1.5">
                    <span class=" text-primary text-2xl font-title font-bold uppercase dark:text-white">{{ appConfig.name }}</span>
                </NuxtLink>
            </div>
            <div class="flex lg:hidden">
                <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 dark:text-white" @click="mobileMenuOpen = true">
                    <span class="sr-only">Open main menu</span>
                    <span class="i-heroicons-bars-3 h-6 w-6" aria-hidden="true"></span>
                </button>
            </div>
            <div class="hidden lg:flex lg:gap-x-12">
                <NuxtLink v-for="item in navigation" :to="item.data.url" class="text-lg font-semibold leading-6 text-gray-900 dark:text-white">{{ item.data.text }}</NuxtLink>
            </div>
            <div class="hidden lg:flex lg:flex-1 lg:justify-end">
                <a @click="signIn('azure-ad-b2c', { callbackUrl: '/admin/dashboard' })" class="text-lg font-semibold leading-6 text-gray-900 cursor-pointer dark:text-white">
                    Log in
                    <span aria-hidden="true">&rarr;</span>
                </a>
            </div>
        </nav>
        
        <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
            <div class="fixed inset-0 z-50">
                <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div class="flex items-center justify-end">
                        <button type="button" class="-m-2.5 rounded-md p-2.5 h-11 text-gray-700" @click="mobileMenuOpen = false">
                            <span class="sr-only">Close menu</span>
                            <span class="i-heroicons-x-mark h-6 w-6" aria-hidden="true"></span>
                        </button>
                    </div>

                    <div class="mt-6 flow-root">
                        <div class="-my-6 divide-y divide-gray-500/10">
                            <div class="space-y-2 py-6">
                                <NuxtLink v-for="item in navigation" :to="item.data.url" @click="setIsOpen(false)" class="-mx-3 block px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.data.text }}</NuxtLink>
                            </div>
                            <div class="py-6">
                                <a @click="signIn('azure-ad-b2c', { callbackUrl: '/admin/dashboard' })" class="-mx-3 block px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer">Log in</a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </div>
        </Dialog>
    </header>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { fetchEntries } from '@builder.io/sdk-vue/vue3';
import '@builder.io/sdk-vue/vue3/css'

const appConfig = useAppConfig()
const navigation: object[] = ref([])

navigation.value = await fetchEntries({
    model: 'menu-links',
    apiKey: appConfig.builderKey,
    options: {
        fields: 'data',
    },
}).then((data) => { return data.results })

const mobileMenuOpen = ref(false)
function setIsOpen(value) {
    mobileMenuOpen.value = value
}
const { signIn } = useAuth()
</script>